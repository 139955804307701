import SubmitButtonEth from "ethereum/components/SubmitButtonEth";
import React, { useState } from "react";
import SubmitButtonSol from "solana/SubmitButtonSol";

const ConenctWalletPrompt = ({ setNetwork }) => {
  return (
    <div>
      <div className="font-bold text-5xl">Gabi Game Bot</div>
      <div className="font-bold text-2xl md:w-2/3 mx-auto pt-10">
        Telegram games with your favorite projects. Deposit with Gabi and start
        playing on Telegram Today
      </div>
      <div className="font-bold underline pt-10 pb-4">Connect Wallet</div>
      <div className="flex flex-col md:flex-row w-full md:justify-center items-center text-white font-bold">
        <button
          onClick={() => setNetwork("ethereum")}
          className="bg-gray-600 py-[10px] rounded-md md:mr-12 px-2 w-1/2 md:w-1/6 mb-4 mt-4 md:mt-0 md:mb-0"
        >
          Ethereum
        </button>
        <button
          onClick={() => setNetwork("solana")}
          className="bg-[#854FED] py-[10px] rounded-md w-1/2 px-2 md:w-1/6"
        >
          Solana
        </button>
      </div>
    </div>
  );
};

export default ConenctWalletPrompt;
