import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  WagmiProvider,
  useAccount,
  useSendTransaction,
  useWaitForTransactionReceipt,
} from "wagmi";
import { config } from "./config";
import React, { useEffect, useState } from "react";
import { WalletOptions } from "./components/wallet-options";
import { Account } from "./components/account";
import DepositModal from "components/DepositModal";
import VerificationModal from "components/VerificationModal";
import ConenctWalletPrompt from "components/ConnectWalletPrompt";
import Balances from "components/Balances";
import ToggleButton from "components/ToggleButton";
import SearchDropdown from "components/SearchDropdown";
import WithdrawModal from "components/WithdrawModal";
import { parseEther } from "viem";
import { InputFields } from "../components/InputFields";
import VerifiedUser from "components/VerifiedUser";

const queryClient = new QueryClient();

function ConnectWallet({ setShowConnectPromp }) {
  const { isConnected, address } = useAccount();
  const [verifyHash, setVerifyHash] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [userName, setUserName] = useState("");
  const [balances, setBalances] = useState([]);
  const [selectedToken, setSelectedToken] = useState("");
  const [amount, setAmount] = useState("");
  const [method, setMethod] = useState("deposit");

  const {
    data: hash,
    isPending,
    sendTransaction: sendTransactionEth,
  } = useSendTransaction();
  const { isLoading: isConfirming, isSuccess: isSuccess } =
    useWaitForTransactionReceipt({
      hash,
    });
  useEffect(() => {
    if (isSuccess) {
      handlePostSendEth(hash);
    } else {
      console.log("NO GOOD");
    }
  }, [isSuccess, hash]);

  const handlePostSendEth = async (hash: string) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        hash: hash,
        tokenId: selectedToken,
        amount: amount,
      }),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/withdrawEth`,
      requestOptions
    );
  };
  const sendEth = async () => {
    // console.log((await token).decimals);
    const to = process.env.REACT_APP_ETH_DEPOSIT_ADDRESS as `0x${string}`;
    const value = "0.01";

    const result = sendTransactionEth({ to, value: parseEther(value) });
  };

  useEffect(() => {
    console.log("USE EFFECT");
    let searchParams = new URLSearchParams(window.location.search);
    // console.log(searchParams.get("hash")); // outputs "m2-m3-m4-m5"
    //@ts-ignore
    setVerifyHash(
      searchParams.get("hash") != null ? searchParams.get("hash") : ""
    );
    //@ts-ignore
    getUserNameFromAddress(address, searchParams.get("hash"));
    // if (searchParams.get("hash")) {
    //   //@ts-ignore
    //   getUserNameFromAddress(address, searchParams.get("hash"));
    // }
  }, [address]);
  //@ts-ignore
  async function getUserNameFromAddress(address: `0x${string}`, hash: string) {
    console.log("IN HERE");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        address: address,
        network: "ethereum",
        hash: verifyHash,
      }),
    };
    // console.log("THE TX WE SHOULD SEND IS "+tx)
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/userData`,
      requestOptions
    );
    const data = await response.json();
    console.log("DATA " + JSON.stringify(data));
    if (data.userData.isVerified) {
      setBalances(data.userData.balances);
      console.log("SET BALANCES " + JSON.stringify(data.userData.balances));
      setIsVerified(true);
      console.log("BALNCES UPDATE " + JSON.stringify(data.userData.balances));
    } else {
      setIsVerified(false);
    }
  }
  return isConnected ? (
    <>
      {/* <Account /> */}
      {isVerified ? (
        <VerifiedUser
          setMethod={setMethod}
          method={method}
          balances={balances}
          selectedToken={selectedToken}
          setSelectedToken={setSelectedToken}
          amount={amount}
          setAmount={setAmount}
          withdrawFunction={sendEth}
          network="etherum"
          account={address}
          getTokenName={undefined}
          setShowConnectPromp={setShowConnectPromp}
        />
      ) : (
        <VerificationModal
          network={"ethereum"}
          hash={verifyHash}
          userName={userName}
          //@ts-ignore
          address={address}
        />
      )}
    </>
  ) : (
    <div>
      <WalletOptions />
    </div>
  );
}

function Ethereum({ setShowConnectPromp }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <ConnectWallet setShowConnectPromp={setShowConnectPromp} />
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default Ethereum;
