import React, { useCallback, useEffect, useState } from "react";
import {
  useAccount,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { abi } from "../abi";
import { config } from "../config";
import { getToken } from "@wagmi/core";
import DepositButton from "components/DepositButton";
import SubmitButton from "components/SubmitButton";
export type SubmitButtonSolProps = {
  amount: string;
  tokenAddress: string;
  // any props that come into the component
};

const SubmitButtonSol = ({ amount, tokenAddress }: SubmitButtonSolProps) => {
  const { data: hash, error, isPending, writeContract } = useWriteContract();
  const account = useAccount();
  const { isLoading: isConfirming, isSuccess: isSuccess } =
    useWaitForTransactionReceipt({
      hash,
    });
  useEffect(() => {
    if (isSuccess) {
      handlePostTransaction(hash);
    }
  }, [isSuccess]);
  const handleSendErc = async () => {
    const token = getToken(config, {
      // @ts-ignore
      address: tokenAddress,
      formatUnits: "ether",
    });
    // console.log((await token).decimals);
    const decimalAmount = parseFloat(amount) * 10 ** (await token).decimals;
    console.log(decimalAmount);
    writeContract({
      abi,
      // @ts-ignore
      address: tokenAddress,
      functionName: "transfer",
      args: [
        // @ts-ignore
        `${process.env.REACT_APP_ETH_DEPOSIT_ADDRESS}`,
        BigInt(decimalAmount),
      ],
    });
    console.log("I AM HERE " + hash);
    if (error) {
      console.log("ERROR " + error);
    } else {
      console.log("OH NO ERROR");
    }
  };

  const handlePostTransaction = async (_hash: any) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ hash: _hash, network: "ethereum" }),
    };
    console.log(`Thing is ${process.env.REACT_APP_API_URL}`);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/deposit`,
      requestOptions
    );
    // debugger;
    const data = await response.json();
    console.log("DATA IS " + JSON.stringify(data));
    console.log("Return data IS " + JSON.stringify(data));
  };

  return (
    <div>
      {/* <DepositModal submitTransaction={handleSendTx}/> */}
      {/* <button onClick={handleSendErc}>Deposit</button> */}
      <SubmitButton submitFunction={handleSendErc} buttonText="Deposit" />
    </div>
  );
};

export default SubmitButtonSol;
