import SubmitButtonEth from "ethereum/components/SubmitButtonEth";
import React, { useEffect, useState } from "react";
import { signMessage } from "@wagmi/core";
import SubmitButtonSol from "solana/SubmitButtonSol";
import { config } from "ethereum/config";
import { useAccount } from "wagmi";
import signSolMessage from "solana/utils/signSolMessage";
import { useWallet } from "@solana/wallet-adapter-react";
import bs58 from "bs58";
import { PublicKey } from "@solana/web3.js";
import { useNavigate } from "react-router-dom";

export type VerificationModalProps = {
  network: string;
  hash: string;
  userName: string;
  address: string;
  // any props that come into the component
};
const VerificationModal = ({
  network,
  hash,
  userName,
  address,
}: VerificationModalProps) => {
  const { signMessage: signMsg, publicKey } = useWallet();
  const navigate = useNavigate();
  const message =
    "Welcome to the Gabi Gaming App. Sign the message to get started";
  const verifyUser = async (
    result: any,
    network: string,
    publicKey?: PublicKey
  ) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body:
        network === "ethereum"
          ? JSON.stringify({
              signature: result,
              address: address,
              network: "ethereum",
              urlHash: hash,
            })
          : JSON.stringify({
              publicKey: publicKey.toBase58(),
              signature: bs58.encode(result),
              network: "solana",
              urlHash: hash,
            }),
    };
    // console.log("THE TX WE SHOULD SEND IS "+tx)
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/verifySignature`,
      requestOptions
    );
    const data = await response.json();
  };
  const handleSignMessage = async () => {
    // console.log("HASH IS " + hash);
    if (network === "ethereum") {
      console.log("HERE");
      const result = await signMessage(config, {
        //@ts-ignore
        account: address,
        message: message,
      });
      const verified = await verifyUser(result, "ethereum");
      console.log("verified " + JSON.stringify(verified));
      window.location.href = "/";
    } else if (network == "solana") {
      console.log("HERE 2");
      const signedMessage = await signSolMessage(message, signMsg);
      console.log(`MEssaage digned man ${JSON.stringify(signedMessage)}`);
      console.log("MESSAGE SIGNED " + signedMessage);
      await verifyUser(signedMessage, "solana", publicKey);
      window.location.href = "/";
    }
  };
  return (
    <>
      {hash ? (
        <div className="h-24 text-2xl">
          <div> Welcome {userName} </div>
          <div>
            <p>
              Please use your wallet to sign the message and link your wallet to
              your account
            </p>
            <button
              className="bg-gray-400 py-4 px-6 rounded-lg"
              onClick={handleSignMessage}
            >
              Sign Message
            </button>
          </div>
        </div>
      ) : (
        <div className="font-bold">
          Please visit the{" "}
          <a href={process.env.REACT_APP_VERIFY_BOT_LINK}>setup bot</a> and
          enter 'start' to get verified
        </div>
      )}
    </>
  );
};

export default VerificationModal;
