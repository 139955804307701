import SubmitButtonEth from "ethereum/components/SubmitButtonEth";
import React, { useState } from "react";
import SubmitButtonSol from "solana/SubmitButtonSol";
import DepositModal from "./DepositModal";
import WithdrawModal from "./WithdrawModal";
import ToggleButton from "./ToggleButton";
import SearchDropdown from "./SearchDropdown";
import { InputFields } from "./InputFields";

const DepositWithdraw = ({
  network,
  amount,
  setAmount,
  selectedToken,
  withdrawFunction,
  method,
  setMethod,
  balances,
  setSelectedToken,
  account,
  getTokenName,
  setShowConnectPromp,
}) => {
  return (
    <div>
      <div className="text-5xl font-[800]">Deposit / Withdraw</div>
      <div className="text-2xl font-[800] w-2/3 pt-4 pb-8 mx-auto">
        Deposit or Withdraw any token on the ethereum or Solana blockchain{" "}
      </div>
      <button
        onClick={() => {
          console.log("CLICKED");
          setShowConnectPromp(true);
        }}
        className=""
      >
        <span className="underline font-bold">Current account:</span> {account}
      </button>
      <ToggleButton setFunction={setMethod} />
      <SearchDropdown
        balances={balances}
        showBalances={method === "withdraw" ? true : false}
        setToken={setSelectedToken}
        network={network}
        method={method}
        getTokenName={getTokenName}
      />
      <InputFields
        amount={amount}
        setAmount={setAmount}
        selectedToken={selectedToken}
      />
      {method === "deposit" ? (
        <DepositModal amount={amount} token={selectedToken} network={network} />
      ) : (
        <WithdrawModal
          amount={amount}
          token={selectedToken}
          network={network}
          clickFunction={withdrawFunction}
        />
      )}
    </div>
  );
};

export default DepositWithdraw;
