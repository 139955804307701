import React from "react";
import { useAccount, useDisconnect, useEnsAvatar, useEnsName } from "wagmi";

export function InputFields({ amount, setAmount, selectedToken }) {
  return (
    <div>
      <div className=" justify-center w-full py-4">
        <div className="pr-4 text-lg font-bold">Token Amount:</div>
        <div>
          <input
            type="text"
            className="w-1/4 relative z-[1] border-2 border-[#458C78] rounded-lg text-center"
            placeholder="0"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
      </div>
      {/* 
      <div>Token : {selectedToken}</div> */}
      {/* <div>Amount : {amount} </div> */}
    </div>
  );
}
