import SubmitButtonEth from "ethereum/components/SubmitButtonEth";
import React, { useEffect, useState } from "react";
import SubmitButtonSol from "solana/SubmitButtonSol";
import {
  Keypair,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction,
  sendAndConfirmTransaction,
} from "@solana/web3.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
// import { config } from "../ethereum/config";
import {
  useAccount,
  useSendTransaction,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { parseEther } from "viem";
import SubmitButton from "./SubmitButton";
export type WithdrawModalProps = {
  token: string;
  amount: string;
  network: string;
  clickFunction: any;
};
const WithdrawModal = ({
  token,
  amount,
  network,
  clickFunction,
}: WithdrawModalProps) => {
  const { publicKey, sendTransaction } = useWallet();
  return <SubmitButton submitFunction={clickFunction} buttonText="Withdraw" />;
};

export default WithdrawModal;
