import SubmitButtonEth from "ethereum/components/SubmitButtonEth";
import React, { useState } from "react";
import SubmitButtonSol from "solana/SubmitButtonSol";
import WithdrawModal from "./WithdrawModal";
import ToggleButton from "./ToggleButton";
import SearchDropdown from "./SearchDropdown";
import { InputFields } from "./InputFields";
import DepositModal from "./DepositModal";
import DepositWithdraw from "./DepositWithdraw";

const VerifiedUser = ({
  setMethod,
  method,
  balances,
  selectedToken,
  setSelectedToken,
  amount,
  setAmount,
  withdrawFunction,
  network,
  account,
  getTokenName,
  setShowConnectPromp,
}) => {
  return (
    <div>
      <DepositWithdraw
        network={network}
        amount={amount}
        selectedToken={selectedToken}
        withdrawFunction={withdrawFunction}
        method={method}
        setAmount={setAmount}
        setMethod={setMethod}
        balances={balances}
        setSelectedToken={setSelectedToken}
        account={account}
        getTokenName={getTokenName}
        setShowConnectPromp={setShowConnectPromp}
      />
    </div>
  );
};

export default VerifiedUser;
