import React, { createRef, useEffect, useRef, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import hotTokensSol from "../data/hot_tokens_sol.json";
import hotTokensEth from "../data/hot_tokens_eth.json";
import { PublicKey } from "@solana/web3.js";

const languageOptions = [
  { key: "fArabic", text: "Arabic", value: "fArabic" },
  { key: "Chinese", text: "Chinese", value: "Chinese" },
  { key: "Danish", text: "Danish", value: "Danish" },
  { key: "Dutch", text: "Dutch", value: "Dutch" },
  { key: "English", text: "English", value: "English" },
  { key: "French", text: "French", value: "French" },
  { key: "German", text: "German", value: "German" },
  { key: "Greek", text: "Greek", value: "Greek" },
  { key: "Hungarian", text: "Hungarian", value: "Hungarian" },
  { key: "Italian", text: "Italian", value: "Italian" },
  { key: "Japanese", text: "Japanese", value: "Japanese" },
  { key: "Korean", text: "Korean", value: "Korean" },
  { key: "Lithuanian", text: "Lithuanian", value: "Lithuanian" },
  { key: "Persian", text: "Persian", value: "Persian" },
  { key: "Polish", text: "Polish", value: "Polish" },
  { key: "Portuguese", text: "Portuguese", value: "Portuguese" },
  { key: "Russian", text: "Russian", value: "Russian" },
  { key: "Spanish", text: "Spanish", value: "Spanish" },
  { key: "Swedish", text: "Swedish", value: "Swedish" },
  { key: "Turkish", text: "Turkish", value: "Turkish" },
  { key: "Vietnamese", text: "Vietnamese", value: "Vietnamese" },
];

export type SearchDropdownProps = {
  setToken: any;
  balances: any;
  showBalances: boolean;
  network: string;
  method: string;
  getTokenName: any;
  // any props that come into the component
};
const SearchDropdown = ({
  setToken,
  balances,
  showBalances,
  network,
  method,
  getTokenName,
}: SearchDropdownProps) => {
  const hotTokens = network === "solana" ? hotTokensSol : hotTokensEth;
  const [dropdownValue, setDropdownValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  // const dropdownRef = createRef();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const getDisplayContract = (contract) => {
    const first4Chars = contract.substring(0, 4); // returns "hell"
    const last4Chars = contract.substring(contract.length - 4, contract.length);
    return first4Chars + "..." + last4Chars;
  };
  const [hotTokensText, setHotTokensText] = useState("Hot Tokens");
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click event target is not inside the component
      //@ts-ignore
      if (!dropdownRef.current.contains(event.target)) {
        // Handle the click outside event
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);
  const originalTokenList = hotTokens.hotTokens.map((token, index) => {
    return {
      name: token.name,
      contract: token.contract,
      displayContract: getDisplayContract(token.contract),
      balance: null,
      searchValue: `${token.name}${token.contract}`,
    };
  });
  const originalBalanceList = balances.map((token, index) => {
    return {
      name: token.token_name,
      contract: token.token,
      displayContract: getDisplayContract(token.token),
      balance: token.balance,
      searchValue: `${token.token_name}${token.token}`,
    };
  });
  const [tokenList, setTokenList] = useState(originalTokenList);
  useEffect(() => {
    console.log("BALANCE SHOW CHANGE");
    showBalances
      ? setTokenList(originalBalanceList)
      : setTokenList(originalTokenList);
  }, [showBalances]);
  const handleInputChange = async (e) => {
    setDropdownValue(e);
    // console.log(e.toLowerCase());
    const result = originalTokenList.filter((item) =>
      item.searchValue.toLowerCase().includes(e.toLowerCase())
    );
    if (result.length > 0 || e === "") {
      setHotTokensText("Hot Tokens");
    } else {
      setHotTokensText("Other Tokens");
    }
    if (result.length === 0 && e !== "") {
      const tokenData = await getTokenName(e);
      console.log(JSON.stringify(tokenData));
      if (tokenData) {
        console.log("GET it update NAME");
        setTokenList([
          {
            name: tokenData.tokenName,
            contract: e,
            displayContract: getDisplayContract(e),
            balance: null,
            searchValue: "",
          },
        ]);
      } else {
        setTokenList(result);
      }
      // name: token.name,
      // contract: token.contract,
      // displayContract: getDisplayContract(token.contract),
      // balance: null,
      // searchValue: `${token.name}${token.contract}`,
    } else {
      setTokenList(result);
    }
    // result.length > 0 && (!e || e === "")
    //   ? setHotTokensText("Other Tokens")
    //   : setHotTokensText("Hot Tokens");

    // if (!e || e === "") setShowDropdown(true);
  };
  const handleClick = () => {
    setShowDropdown(true);
  };

  const handleTokenSelect = (selectedToken: string) => {
    setDropdownValue(selectedToken);
    setToken(selectedToken);
    setShowDropdown(false);
  };

  return (
    <div className="flex justify-center w-10/12 mx-auto pt-6">
      <div className="underline font-bold pr-4 font-bold pt-2">CA:</div>
      <div ref={dropdownRef} className="flex justify-center w-full">
        <div className="relative flex flex-col items-center w-full">
          <input
            className="w-full relative z-[201] border-2 border-[#458C78] rounded-lg px-2 text-sm text-center pt-2"
            value={dropdownValue}
            onChange={(e) => handleInputChange(e.target.value)}
            onClick={handleClick}
          />

          {showDropdown ? (
            <div className="bg-gray-200 absolute z-[200] w-[98%] pt-8">
              <div className="font-bold absolute z-[200] bg-gray-200 w-[95%] pt-2 mb-2">
                {hotTokensText}
              </div>
              <div className="overflow-y-scroll max-h-[100px] z-[200] mt-8 pb-4">
                {tokenList.map((token, index) => {
                  return (
                    <div
                      className="hover:bg-sky-700 hover:text-white text-left px-4"
                      onClick={() => {
                        handleTokenSelect(token.contract);
                      }}
                    >
                      <button className="pt-2 text-left">
                        {token.balance && (
                          <div className="">{token.balance}</div>
                        )}
                        <span className="pr-4 underline font-bold">
                          {token.name}
                        </span>
                        <span className="">{token.displayContract}</span>
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
  //   <Dropdown
  //     button
  //     className="icon"
  //     floating
  //     labeled
  //     icon="world"
  //     options={languageOptions}
  //     search
  //     text="Select Language"
  //   />
};

export default SearchDropdown;
