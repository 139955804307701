import {
  createAssociatedTokenAccountInstruction,
  createTransferInstruction,
  getAccount,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  clusterApiUrl,
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";

import Balances from "components/Balances";
import ConenctWalletPrompt from "components/ConnectWalletPrompt";
import DepositModal from "components/DepositModal";
import SearchDropdown from "components/SearchDropdown";
import ToggleButton from "components/ToggleButton";
import VerificationModal from "components/VerificationModal";
import WithdrawModal from "components/WithdrawModal";
import { sign } from "crypto";
import { InputFields } from "components/InputFields";
import React, { useCallback, useEffect, useState } from "react";
import VerifiedUser from "components/VerifiedUser";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

const SolMain = ({ setShowConnectPromp }) => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const [hash, setHash] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [userName, setUserName] = useState("");
  const [pubKeyString, setPubKeyString] = useState("");
  const [balances, setBalances] = useState([]);
  const [selectedToken, setSelectedToken] = useState("");
  const [amount, setAmount] = useState("");
  const [method, setMethod] = useState("deposit");
  //   console.log("KEY " + publicKey.toBase58());
  const handlePostSendSol = async (sig: string) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        signature: sig,
        tokenId: selectedToken,
        amount: amount,
      }),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/withdrawSol`,
      requestOptions
    );
    // debugger;
    const data = await response.json();
    console.log("DATA IS " + JSON.stringify(data));
    console.log("Return data IS " + JSON.stringify(data));
  };

  const sendSol = async () => {
    const toPubKey = `${process.env.REACT_APP_SOL_DEPOSIT_ADDRESS}`;
    // const keypair = Keypair.fromPublicKey(toPubKey);
    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: new PublicKey(toPubKey),
        lamports: LAMPORTS_PER_SOL / 100,
      })
    );
    const {
      context: { slot: minContextSlot },
      value: { blockhash, lastValidBlockHeight },
    } = await connection.getLatestBlockhashAndContext();
    // Sign transaction, broadcast, and confirm
    const signature = await sendTransaction(transaction, connection, {
      minContextSlot,
    });
    await connection.confirmTransaction({
      blockhash,
      lastValidBlockHeight,
      signature,
    });

    // console.log("SIGNATURE", signature);
    handlePostSendSol(signature);
  };

  useEffect(() => {
    console.log("IN THIS USE EFFE");
    let searchParams = new URLSearchParams(window.location.search);
    // console.log(searchParams.get("hash")); // outputs "m2-m3-m4-m5"
    console.log("SET HASH MAN");
    //@ts-ignore
    setHash(searchParams.get("hash"));
    if (publicKey && publicKey.toBase58()) {
      //@ts-ignore
      setPubKeyString(publicKey.toBase58());
      //@ts-ignore
      getUserNameFromAddress(publicKey.toBase58(), searchParams.get("hash"));
    } else {
      console.log("IN ELSE");
    }
  }, [publicKey]);
  async function getUserNameFromAddress(address: string, hash: string) {
    console.log("IN HERE");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        address: address,
        network: "solana",
        hash: hash,
      }),
    };
    // console.log("THE TX WE SHOULD SEND IS "+tx)
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/userData`,
      requestOptions
    );
    const data = await response.json();
    // console.log("DATA IS " + JSON.stringify(data));
    if (data.userData.isVerified) {
      setBalances(data.userData.balances);
      // console.log("SET BALANCES " + JSON )
      setIsVerified(true);
      // console.log("BALNCES UPDATE " + JSON.stringify(data.userData.balances));
    } else {
      setIsVerified(false);
    }
  }

  async function getTokenName(address: any) {
    console.log("IN this one");
    try {
      new PublicKey(address);
    } catch (e) {
      console.log("PUBLIC KEY BOD");
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        address: address,
      }),
    };
    // console.log("THE TX WE SHOULD SEND IS "+tx)
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/solTokenData`,
      requestOptions
    );
    const data = await response.json();
    return data.token;
    // console.log("DATA IS " + JSON.stringify(data));
  }

  return (
    <div>
      {publicKey ? (
        <>
          {isVerified ? (
            <VerifiedUser
              setMethod={setMethod}
              method={method}
              balances={balances}
              selectedToken={selectedToken}
              setSelectedToken={setSelectedToken}
              amount={amount}
              setAmount={setAmount}
              withdrawFunction={sendSol}
              network="solana"
              account={publicKey.toBase58()}
              getTokenName={getTokenName}
              setShowConnectPromp={setShowConnectPromp}
            />
          ) : (
            <>
              <VerificationModal
                network={"solana"}
                hash={hash}
                userName={userName}
                address={pubKeyString}
              />
            </>
          )}
        </>
      ) : (
        <>
          <WalletMultiButton />
        </>
      )}
    </div>
  );
};

export default SolMain;
