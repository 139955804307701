import logo from './logo.svg'
import './App.css'
import React, { useState } from 'react'
import Solana from 'solana/main'
import Ethereum from 'ethereum/main'
import { Home } from 'pages/Home'
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom'
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
