import SubmitButtonEth from "ethereum/components/SubmitButtonEth";
import React, { useState } from "react";
import SubmitButtonSol from "solana/SubmitButtonSol";
export type DepositModalProps = {
  network: string;
  amount: string;
  token: string;
  // any props that come into the component
};
const DepositModal = ({ network, amount, token }: DepositModalProps) => {
  // const handleSubmit = () => {
  //   submitTransaction(amount, token);
  // };
  return (
    <>
      {network === "solana" ? (
        <SubmitButtonSol amount={amount} tokenAddres={token} />
      ) : (
        <SubmitButtonEth amount={amount} tokenAddress={token} />
      )}
    </>
  );
};

export default DepositModal;
