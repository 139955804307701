import React, { useState } from "react";

const ToggleButton = ({ setFunction }) => {
  const activeColor = "bg-[#006D51]";
  const inActiveColor = "bg-gray-400";
  const [depositColor, setDepositColor] = useState(activeColor);
  const [withdrawColor, setWithdrawColor] = useState(inActiveColor);
  const [depositMargin, setDepositMargin] = useState("relative z-20 -mr-4");
  const [withdrawMargin, setWithdrawMargin] = useState("relative z-1");
  const updateMethod = (method: string) => {
    if (method === "withdraw") {
      setWithdrawColor(activeColor);
      setDepositColor(inActiveColor);
      setDepositMargin("relative z-1");
      setWithdrawMargin("relative z-20 -ml-4");
    } else if (method === "deposit") {
      setWithdrawColor(inActiveColor);
      setDepositColor(activeColor);
      setDepositMargin("relative z-20 -mr-4");
      setWithdrawMargin("relative z-1");
    }
    setFunction(method);
  };

  return (
    <div className="pt-4 text-white font-bold">
      {" "}
      <button
        onClick={() => {
          updateMethod("deposit");
        }}
        className={`${depositColor} px-6 py-4 rounded-lg ${depositMargin}`}
      >
        Deposit
      </button>
      <button
        onClick={() => {
          updateMethod("withdraw");
        }}
        className={`${withdrawColor} px-6 py-4 rounded-lg ${withdrawMargin}`}
      >
        Withdraw
      </button>
      <div className="w-1/6 mx-auto ">
        {/* <label className="float-left block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Amount
        </label> */}
      </div>
    </div>
  );
};

export default ToggleButton;
