import React, { useState } from "react";

const SubmitButton = ({ submitFunction, buttonText }) => {
  return (
    <button
      className="bg-[#2C37DF] text-white px-20 py-4 rounded-lg font-bold"
      onClick={submitFunction}
    >
      {buttonText}
    </button>
  );
};

export default SubmitButton;
