import {
  createAssociatedTokenAccountInstruction,
  createTransferInstruction,
  getAccount,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  PublicKey,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import DepositModal from "components/DepositModal";
import SubmitButton from "components/SubmitButton";
import { sign } from "crypto";
import React, { useCallback, useState } from "react";

export type SubmitButtonSolProps = {
  amount: string;
  tokenAddres: string;
  // any props that come into the component
};
const SubmitButtonSol = ({ amount, tokenAddres }: SubmitButtonSolProps) => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const handleSendSpl = async () => {
    if (!publicKey) throw new WalletNotConnectedError();
    if (!publicKey) return;
    console.log(`${process.env.REACT_APP_SOL_DEPOSIT_ADDRESS}`);
    const mintToken = new PublicKey(tokenAddres);

    const transactionInstructions: TransactionInstruction[] = [];
    const associatedTokenFrom = await getAssociatedTokenAddress(
      mintToken,
      publicKey
    );
    const fromAccount = await getAccount(connection, associatedTokenFrom);
    const associatedTokenTo = await getAssociatedTokenAddress(
      mintToken,
      new PublicKey(`${process.env.REACT_APP_SOL_DEPOSIT_ADDRESS}`)
    );
    if (!(await connection.getAccountInfo(associatedTokenTo))) {
      transactionInstructions.push(
        createAssociatedTokenAccountInstruction(
          publicKey,
          associatedTokenTo,
          new PublicKey(`${process.env.REACT_APP_SOL_DEPOSIT_ADDRESS}`),
          mintToken
        )
      );
    }
    transactionInstructions.push(
      createTransferInstruction(
        fromAccount.address, // source
        associatedTokenTo, // dest
        publicKey,
        parseFloat(amount) * 1000000000 // transfer 1 USDC, USDC on solana devnet has 6 decimal
      )
    );
    const transaction = new Transaction().add(...transactionInstructions);
    const blockHash = await connection.getLatestBlockhash();
    transaction.feePayer = publicKey;
    transaction.recentBlockhash = blockHash.blockhash;
    console.log("transaction", transaction);
    const {
      context: { slot: minContextSlot },
      value: { blockhash, lastValidBlockHeight },
    } = await connection.getLatestBlockhashAndContext();

    const signature = await sendTransaction(transaction, connection, {
      minContextSlot,
    });
    await connection.confirmTransaction({
      blockhash,
      lastValidBlockHeight,
      signature,
    });
    await handlePostTransaction(signature);
  };
  const handlePostTransaction = async (sig: string) => {
    // hideDeposit();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ signature: sig }),
    };
    console.log(`Thing is ${process.env.REACT_APP_API_URL}`);
    // console.log("THE TX WE SHOULD SEND IS "+tx)
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/deposit`,
      requestOptions
    );
    // debugger;
    const data = await response.json();
    console.log("DATA IS " + JSON.stringify(data));
    //     var tempBalances = userBalances.map((balance, index) => (
    // // <p key={index}>Hello, {person.name} from {person.country}!</p>
    //       {balance}
    //     ))
    // updateBalanceOnDeposit(data.balances)
    console.log("Return data IS " + JSON.stringify(data));
  };

  return (
    <div>
      {/* <DepositModal submitTransaction={handleSendTx}/> */}
      <SubmitButton submitFunction={handleSendSpl} buttonText="Deposit" />
    </div>
  );
};

export default SubmitButtonSol;
