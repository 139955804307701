import * as React from 'react'
import { Connector, useConnect } from 'wagmi'

export function WalletOptions() {
  const { connectors, connect } = useConnect()

  return (
    <div className="pt-8">
      {connectors.map((connector: any) => (
        <button
          className="hover:bg-gray-400 mr-4 bg-gray-200 border px-8 py-4 border-md rounded-lg"
          key={connector.uid}
          onClick={() => connect({ connector })}
        >
          {connector.name}
        </button>
      ))}
    </div>
  )
}
