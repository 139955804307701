import ConenctWalletPrompt from "components/ConnectWalletPrompt";
import Ethereum from "ethereum/main";
import * as React from "react";
import Solana from "solana/main";
import { Connector, useConnect } from "wagmi";

export function Home() {
  //   const { connectors, connect } = useConnect()
  const [showConnectPrompt, setShowConnectPrompt] = React.useState(true);
  const [network, setNetwork] = React.useState("solana");
  const [networkComponent, setNetworkComponent] = React.useState(
    <Solana setShowConnectPrompt={setShowConnectPrompt} />
  );
  const switchNetwork = () => {
    // if(network ===)
    network === "ethereum" ? setNetwork("solana") : setNetwork("ethereum");
  };
  const handleNetworkClick = (network: string) => {
    if (network === "ethereum") {
      setNetworkComponent(
        <Ethereum setShowConnectPromp={setShowConnectPrompt} />
      );
    } else {
      setNetworkComponent(
        <Solana setShowConnectPrompt={setShowConnectPrompt} />
      );
    }
    setShowConnectPrompt(false);
    console.log("NEtwork " + network);
  };
  return (
    <div className="px-2 md:px-0">
      {" "}
      <div>
        {/* <button
          className="font-aspira bg-gray-400 py-4 px-8 text-white rounded-md my-6 font-bold"
          id="switchButton"
          onClick={switchNetwork}
        >
          Use {network === "ethereum" ? "Solana" : "Ethereum"}
        </button> */}
      </div>
      <div className="pt-12 bg-white mt-20 pb-20 border-2 border-[#5A8573] md:w-1/3 px-4 rounded-lg mx-auto font-aspira App font-aspira">
        {/* {network === "ethereum" ? <Ethereum /> : <Solana />} */}
        {showConnectPrompt ? (
          <ConenctWalletPrompt setNetwork={handleNetworkClick} />
        ) : (
          networkComponent
        )}
      </div>
    </div>
  );
}
